<template>
  <div @click="refreshAds" class="absolute z-50 left-1/2 transform -translate-x-1/2 top-16 bg-white rounded-full border border-normal hover:border-border-hover hover:bg-background-normal px-2.5 py-2 cursor-pointer whitespace-nowrap">
    <div class="flex items-center gap-2">
      <div class="flex">
        <div
          v-for="(ad, index) of getNewAds.slice(0, 3)"
          :key="index"
          class="-mr-2"
        >
          <img
            :src="ad.avatar"
            :alt="`Advertisement ${index + 1} avatar`"
            class="w-4 h-4 rounded-full object-cover"
          >
        </div>
      </div>
      <BaseText
        type="label"
        size="sm"
        class="ml-3 text-text-normal select-none"
      >
        Refresh {{ getNewAds.length }} new
      </BaseText>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'NewAdvertisementButton',
  computed: {
    ...mapGetters('AdvertisementsModule', ['getNewAds'])
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_NEW_ADS']),
    refreshAds () {
      this.SET_NEW_ADS([])
      this.$emit('click')
    }
  }
}
</script>
