<template>
  <div :ref="`tagsListContainer-${index}`" class="w-full max-w-full flex flex-nowrap items-center gap-1">
    <!-- Render the ad's list of tags -->
    <transition-group tag="div" class="flex items-center gap-1">
      <div v-for="(tag, index) in visibleTags" :key="tag.id" class="px-3 py-1 rounded-full"
      :style="{ 
        backgroundColor: tag.color ? colors[tag.color].fill : colors['gray'].fill,
        color: tag.color ? colors[tag.color].stroke : colors['gray'].stroke,
      }">
        <div class="truncate font-medium" :style="{maxWidth: '170px'}">
          {{ tag.name }}
        </div>
      </div>
    </transition-group>
    <!-- Renders the "+x" hidden tags indicator -->
    <transition>
      <div v-if="hiddenTags.length > 0" class="relative px-2 py-1 rounded-full text-text-muted bg-neutral-50 font-medium"
      @mouseenter="hiddenIndicatorHovered = true" @mouseleave="hiddenIndicatorHovered = false">
        +{{ hiddenTags.length }}
        <transition>
          <div v-if="hiddenIndicatorHovered" @mouseenter="hiddenIndicatorHovered = false" class="hidden-tags-tooltip pt-1.5 z-50">
            <div class="px-1 py-2 rounded-lg bg-neutral-700 shadow-lg">
              <div v-for="tag in hiddenTags" :key="tag.id" 
              class="px-3 py-1 text-neutral-200 font-medium text-sm max-w-full whitespace-nowrap truncate">
                {{ tag.name }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
  import FirebaseAPI from '@/api/firebase'
  import TAG_COLORS from '../../../utils/TagColors'

  export default {
    name: 'TagsListDisplay',
    props: {
      tags: {
        type: Array,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        adTags: [],
        visibleTags: [],
        colors: TAG_COLORS,
        resizeTimeout: null,
        hiddenIndicatorHovered: false
      }
    },
    computed: {
      hiddenTags () {
        return this.adTags.filter(tag => !this.visibleTags.includes(tag));
      }
    },
    watch: {
      tags: {
        handler () {
          this.fetchAdTags();
        },
        immediate: true
      }
    },
    mounted () {
      // We wait to add the event listener so we don't catch the initial resize event
      setTimeout(() => {
        window.addEventListener('resize', this.handleWindowResize);
      }, 1000);
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.handleWindowResize);
      clearTimeout(this.resizeTimeout);
    },
    methods: {
      async fetchAdTags () {
        if (this.tags.length) {
          this.adTags = await FirebaseAPI.Tags.getTagsArray(this.tags);
          this.$nextTick(() => {
            this.calculateVisibleTags();
          })
        }
      },
      calculateVisibleTags () {
        if (!this.$refs[`tagsListContainer-${this.index}`]) return
        const containerWidth = this.$refs[`tagsListContainer-${this.index}`].offsetWidth - 40; // -30 for "+x" tag
        let totalWidth = 0;
        let newVisibleTags = [];

        // To calculate the expected width of the tag, we render a temporary element and capture its width
        this.adTags.forEach(tag => {
          const compNode = document.createElement('span');
          compNode.classList.add('px-4'); // Apply the same styles as the tag element (+1 x-padding for flex gap)
          compNode.textContent = tag.name;
          document.body.appendChild(compNode);
          const tagWidth = compNode.offsetWidth; // Compute the rendered width of the tag
          document.body.removeChild(compNode);

          if (totalWidth + tagWidth < containerWidth) {
            totalWidth += tagWidth;
            newVisibleTags.push(tag);
          }

          this.visibleTags = newVisibleTags;
        });
      },
      handleWindowResize () {
        // We debounce the resize event to avoid unnecessary calculations (and to give resize transitions time to play)
        if (this.visibleTags.length > 0) this.visibleTags = [];
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          this.calculateVisibleTags();
        }, 1000);
      }
    }
  }
</script>

<style scoped>
  .hidden-tags-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 125px;
    max-width: 200px;
  }
  .v-enter-active, .v-leave-active {
    transition: opacity 150ms ease-in-out;
  }
  .v-enter-from, .v-enter, .v-leave-to {
    opacity: 0;
  }
  .v-enter-to, .v-leave-from {
    opacity: 1;
  }
</style>