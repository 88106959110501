<template>
  <!-- Create Advertisement Modal -->
  <BaseModal
    :card-width="'650px'"
    @close="$emit('close')"
  >
    <template #toolbar>
      <div
        v-if="!spyderView"
        class="flex text-xl text-black font-medium"
      >
        <div
          class="bg-gray-200 text-gray-600 w-12 h-12 rounded-full flex justify-center items-center"
        >
          <svg
            width="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3475_2469)">
              <path
                d="M10.0596 7.82199L6.54397 5.62472C6.40848 5.54005 6.23773 5.53557 6.09802 5.613C5.95827 5.69043 5.87158 5.83762 5.87158 5.99738V10.3919C5.87158 10.5517 5.95827 10.6988 6.09802 10.7763C6.1644 10.8131 6.23776 10.8314 6.31104 10.8314C6.39198 10.8314 6.47281 10.809 6.54395 10.7646L10.0596 8.5673C10.1881 8.487 10.2661 8.34617 10.2661 8.19464C10.2661 8.04312 10.1881 7.90229 10.0596 7.82199ZM6.75052 9.59902V6.79024L8.99753 8.19464L6.75052 9.59902Z"
                fill="currentColor"
              />
              <path
                d="M12.9321 2.48175H2.32666C1.11509 2.48175 0.129395 3.46744 0.129395 4.67902V11.7103C0.129395 12.9218 1.11509 13.9075 2.32666 13.9075H12.9321C14.1437 13.9075 15.1294 12.9218 15.1294 11.7103V4.67902C15.1294 3.46744 14.1437 2.48175 12.9321 2.48175ZM14.2505 11.7103C14.2505 12.4372 13.6591 13.0286 12.9321 13.0286H2.32666C1.59972 13.0286 1.0083 12.4372 1.0083 11.7103V4.67902C1.0083 3.95207 1.59972 3.36066 2.32666 3.36066H12.9321C13.6591 3.36066 14.2505 3.95207 14.2505 4.67902V11.7103Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_3475_2469">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.129395 0.694641)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div class="ml-3">
          Video Tutorials

          <div class="text-gray-400 text-base font-normal">
            Tips, Tricks and Tutorials to get the most from Foreplay
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex text-xl text-black font-medium"
      >
        <div
          class="bg-gray-200 text-gray-600 w-12 h-12 rounded-full flex justify-center items-center"
        >
          <img
            src="../../assets/icons/spyder.png"
            alt=""
          >
        </div>

        <div class="ml-3">
          How To Use Spyder
          <div class="text-gray-400 text-base font-normal">
            Save all ads from Facebook Ad Library and track automatically
          </div>
        </div>
      </div>
    </template>

    <template #default>
      <div class="border-t border-gray-300 w-full" />
      <div
        v-if="!spyderView"
        class="hidden sm:flex w-full mt-6 px-8"
      >
        <nav class="flex border-b w-full border-gray-300 select-none">
          <div class="flex space-x-8">
            <a
              value="inspiration"
              class="flex items-center cursor-pointer border-b-2 border-white transition duration-300 text-gray-500 whitespace-nowrap pb-4 px-1 font-medium text-lg"
              :class="
                selectedTab === 'library'
                  ? 'border-blue-500 text-blue-600'
                  : 'hover:text-gray-700 hover:border-gray-300 '
              "
              @click="selectedTab = 'library'"
            >
              <img
                src="../../assets/icons/swipe-file.png"
                class="w-6 h-6 mr-2"
              >
              Swipe File
            </a>

            <a
              value="specs"
              class="flex items-center cursor-pointer border-b-2 border-white transition duration-300 text-gray-500 whitespace-nowrap pb-4 px-1 font-medium text-lg"
              :class="
                selectedTab === 'discovery'
                  ? 'border-blue-500 text-blue-600'
                  : 'hover:text-gray-700 hover:border-gray-300 '
              "
              @click="selectedTab = 'discovery'"
            >
              <img
                src="../../assets/icons/discovery.png"
                class="w-6 h-6 mr-2"
              >
              Discovery
            </a>
            <a
              value="specs"
              class="flex items-center cursor-pointer border-b-2 border-white transition duration-300 text-gray-500 whitespace-nowrap pb-4 px-1 font-medium text-lg"
              :class="
                selectedTab === 'briefs'
                  ? 'border-blue-500 text-blue-600'
                  : 'hover:text-gray-700 hover:border-gray-300 '
              "
              @click="selectedTab = 'briefs'"
            >
              <img
                src="../../assets/icons/briefs.png"
                class="w-6 h-6 mr-2"
              >
              Briefs
            </a>
          </div>
        </nav>
      </div>

      <div
        v-if="!spyderView"
        class="h-full overflow-y-scroll scrollbar-hide px-4"
        style="max-height: calc(90vh - 200px);"
      >
        <div class="px-4 pt-2 mt-4 text-lg flex items-center text-gray-600 font-medium">
          <!-- TLDR SVG -->
          <svg
            width="20"
            viewBox="0 0 16 13"
            fill="none"
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9575 0.615967H2.35205C1.14048 0.615967 0.154785 1.60166 0.154785 2.81323V9.84448C0.154785 11.0561 1.14048 12.0417 2.35205 12.0417H12.9575C14.1691 12.0417 15.1548 11.0561 15.1548 9.84448V2.81323C15.1548 1.60166 14.1691 0.615967 12.9575 0.615967ZM14.2759 9.84448C14.2759 10.5714 13.6845 11.1628 12.9575 11.1628H2.35205C1.62511 11.1628 1.03369 10.5714 1.03369 9.84448V2.81323C1.03369 2.08629 1.62511 1.49487 2.35205 1.49487H12.9575C13.6845 1.49487 14.2759 2.08629 14.2759 2.81323V9.84448Z"
              fill="#BDC0C6"
            />
            <line
              x1="3.09485"
              y1="3.92212"
              x2="12.2147"
              y2="3.92212"
              stroke="#BDC0C6"
              stroke-linecap="round"
            />
            <line
              x1="3.09485"
              y1="6.41211"
              x2="8.52176"
              y2="6.41211"
              stroke="#BDC0C6"
              stroke-linecap="round"
            />
            <line
              x1="3.09485"
              y1="8.90186"
              x2="11.0254"
              y2="8.90186"
              stroke="#BDC0C6"
              stroke-linecap="round"
            />
          </svg>
          TLDR
        </div>
        <div
          class="px-4 text-gray-500 text-sm"
          v-html="displayTLDR"
        />

        <!-- Video Tutorial -->
        <div class="px-4 py-2 mt-4 text-lg flex items-center text-gray-600 font-medium">
          <svg
            width="22"
            viewBox="0 0 16 16"
            fill="none"
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3475_2469)">
              <path
                d="M10.0596 7.82199L6.54397 5.62472C6.40848 5.54005 6.23773 5.53557 6.09802 5.613C5.95827 5.69043 5.87158 5.83762 5.87158 5.99738V10.3919C5.87158 10.5517 5.95827 10.6988 6.09802 10.7763C6.1644 10.8131 6.23776 10.8314 6.31104 10.8314C6.39198 10.8314 6.47281 10.809 6.54395 10.7646L10.0596 8.5673C10.1881 8.487 10.2661 8.34617 10.2661 8.19464C10.2661 8.04312 10.1881 7.90229 10.0596 7.82199ZM6.75052 9.59902V6.79024L8.99753 8.19464L6.75052 9.59902Z"
                fill="#BDC0C6"
              />
              <path
                d="M12.9321 2.48175H2.32666C1.11509 2.48175 0.129395 3.46744 0.129395 4.67902V11.7103C0.129395 12.9218 1.11509 13.9075 2.32666 13.9075H12.9321C14.1437 13.9075 15.1294 12.9218 15.1294 11.7103V4.67902C15.1294 3.46744 14.1437 2.48175 12.9321 2.48175ZM14.2505 11.7103C14.2505 12.4372 13.6591 13.0286 12.9321 13.0286H2.32666C1.59972 13.0286 1.0083 12.4372 1.0083 11.7103V4.67902C1.0083 3.95207 1.59972 3.36066 2.32666 3.36066H12.9321C13.6591 3.36066 14.2505 3.95207 14.2505 4.67902V11.7103Z"
                fill="#BDC0C6"
              />
            </g>
            <defs>
              <clipPath id="clip0_3475_2469">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.129395 0.694641)"
                />
              </clipPath>
            </defs>
          </svg>

          Video Tutorial
        </div>
        <div class="px-4 flex items-center justify-center">
          <iframe
            v-if="selectedTab === 'library'"
            width="100%"
            height="315"
            class="rounded-md"
            src="https://www.youtube.com/embed/O7h2MqJEBKU?si=5PdLjznj537yzkwv"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
          <iframe
            v-else-if="selectedTab === 'discovery'"
            width="100%"
            height="315"
            class="rounded-md"
            src="https://www.youtube.com/embed/4hAAsH0eXG0?si=f0m0tM7ScXXfJLwk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
          <iframe
            v-else-if="selectedTab === 'briefs'"
            width="100%"
            height="315"
            class="rounded-md"
            src="https://www.youtube.com/embed/gUAspEVLZ4M?si=PftRdk_2YkvFBzzy"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>

        <div class="px-4 py-2 mt-4 text-lg flex items-center text-gray-600 font-medium">
          <svg
            width="24"
            viewBox="0 0 16 13"
            fill="none"
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0505 0.978271H2.44507C1.2335 0.978271 0.247803 1.96396 0.247803 3.17554V10.2068C0.247803 11.4184 1.2335 12.4041 2.44507 12.4041H13.0505C14.2621 12.4041 15.2478 11.4184 15.2478 10.2068V3.17554C15.2478 1.96396 14.2621 0.978271 13.0505 0.978271ZM14.3689 10.2068C14.3689 10.9337 13.7775 11.5251 13.0505 11.5251H2.44507C1.71812 11.5251 1.12671 10.9337 1.12671 10.2068V3.17554C1.12671 2.44859 1.71812 1.85718 2.44507 1.85718H13.0505C13.7775 1.85718 14.3689 2.44859 14.3689 3.17554V10.2068Z"
              fill="#BDC0C6"
            />
            <path
              d="M7.72289 8.03411C7.72172 8.03411 7.72056 8.03411 7.71939 8.03411C7.50512 8.03217 7.33277 7.8569 7.33471 7.64244L7.33646 7.44835C7.33646 7.43728 7.33704 7.42602 7.3382 7.41496C7.39197 6.85035 7.76675 6.50429 8.06817 6.22616C8.17026 6.13183 8.26692 6.04275 8.34922 5.94978C8.44956 5.83662 8.59513 5.60488 8.44199 5.3252C8.26556 5.00223 7.83468 4.91081 7.49988 4.98748C7.15013 5.06745 7.02125 5.36615 6.97545 5.53695C6.91994 5.74404 6.70702 5.8669 6.49993 5.81159C6.29283 5.75608 6.16998 5.54316 6.22529 5.33607C6.37998 4.75826 6.78136 4.35552 7.32636 4.23072C8.05983 4.06322 8.79853 4.3596 9.12286 4.95254C9.39284 5.44631 9.31889 6.02567 8.92974 6.4647C8.82163 6.58659 8.70615 6.69334 8.59435 6.7964C8.31583 7.05357 8.14173 7.22553 8.11223 7.47377L8.11068 7.64903C8.10913 7.86273 7.93561 8.03411 7.72289 8.03411Z"
              fill="#BDC0C6"
            />
            <path
              d="M7.72253 9.19803C7.6216 9.19803 7.52068 9.15727 7.44886 9.08546C7.37511 9.01365 7.33435 8.91272 7.33435 8.80985C7.33435 8.70893 7.37511 8.608 7.44886 8.53619C7.59249 8.39256 7.85257 8.39256 7.9962 8.53619C8.06995 8.608 8.11071 8.70893 8.11071 8.80985C8.11071 8.91272 8.06995 9.01171 7.99814 9.08546C7.92438 9.15727 7.8254 9.19803 7.72253 9.19803Z"
              fill="#BDC0C6"
            />
          </svg>

          More Questions?
        </div>

        <div class="grid grid-cols-2 gap-4 w-full px-4 pb-4 pt-2">
          <div
            class="group flex items-center col-span-1 border border-gray-300 px-2 py-2 rounded-md cursor-pointer"
            :class="`hover:border-${getTheme}-500 transiton duration-200`"
            @click="showIntercom"
          >
            <div class="flex items-center justify-center">
              <img
                class="h-4 z-10"
                src="../../assets/images/zach.png"
              >
              <img
                class="rounded-full h-4 -ml-1 -mb-2"
                src="../../assets/images/jake.png"
              >
            </div>

            <span class="ml-2 group-hover:text-blue-500 transiton duration-200">Contact Support</span>

            <div class="ml-auto group-hover:text-blue-500 transiton duration-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </div>
          <a
            href="https://twitter.com/foreplayzach"
            target="_blank"
            class="group flex items-center col-span-1 border border-gray-300 px-2 py-2 rounded-md cursor-pointer"
            :class="`hover:border-${getTheme}-500 transiton duration-200`"
          >
            <TwitterIcon :width="20" />

            <span class="ml-2 group-hover:text-blue-500 transiton duration-200">Connect on Twitter</span>

            <div class="ml-auto group-hover:text-blue-500 transiton duration-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
      <div
        v-else
        class="h-full overflow-y-scroll scrollbar-hide px-4 flex flex-col"
      >
        <iframe
          class=" my-5 max-w-md rounded-lg mx-auto"
          width="100%"
          height="255"
          src="https://www.youtube.com/embed/M6sayYQXsNo?si=3lmzlxNHqhoXqHM6"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />

        <div class="grid grid-cols-2 gap-4 w-full px-4 pb-4 pt-2">
          <div
            class="group flex items-center col-span-1 border border-gray-300 px-2 py-2 rounded-md cursor-pointer"
            :class="`hover:border-${getTheme}-500 transiton duration-200`"
            @click="showIntercom"
          >
            <div class="flex items-center justify-center">
              <img
                class="h-4 z-10"
                src="../../assets/images/zach.png"
              >
              <img
                class="rounded-full h-4 -ml-1 -mb-2"
                src="../../assets/images/jake.png"
              >
            </div>

            <span class="ml-2 group-hover:text-blue-500 transiton duration-200">Contact Support</span>

            <div class="ml-auto group-hover:text-blue-500 transiton duration-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </div>
          <a
            href="https://twitter.com/foreplayzach"
            target="_blank"
            class="group flex items-center col-span-1 border border-gray-300 px-2 py-2 rounded-md cursor-pointer"
            :class="`hover:border-${getTheme}-500 transiton duration-200`"
          >
            <TwitterIcon :width="20" />

            <span class="ml-2 group-hover:text-blue-500 transiton duration-200">Connect on Twitter</span>

            <div class="ml-auto group-hover:text-blue-500 transiton duration-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import TwitterIcon from '../globals/Icons/FilterIcons/TwitterIcon'

export default {
  name: 'CreateAdvertisementModal',
  components: {
    TwitterIcon
  },
  props: {
    currentBoard: {
      type: String,
      default: 'library'
    },
    spyderView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      adBlockedDetected: false,
      selectedTab: 'library',
      libraryTLDR: 'Swipe Library in Foreplay is the best way save ads forever and create a living ad swipe file. Your library contains all the ads saved to your account using our <a href="https://chrome.google.com/webstore/detail/ad-library-save-facebook/eaancnanphggbfliooildilcnjocggjm" target="_blank">Chrome Extension</a> or from Discovery - they are organized into Folders and Boards. ',
      discoveryTLDR: 'Discovery is a real-time feed of over 200k ads being saved by other Foreplay users. We highly recommend using the AI search function to search by product name for example “collagen” or targeted ad copy like “Black Friday” ',
      briefsTLDR: 'Briefs is the fastest way to transform your inspiration into actionable briefs for UGC creators, video editors or graphic designers. Briefs is currently in a closed beta for paid subscribers and version 2 will be launching next month. '
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    displayTLDR () {
      return this[this.selectedTab + 'TLDR']
    }
  },
  async mounted () {},
  methods: {
    showIntercom () {
      window.Intercom('show')
    }
  }
}
</script>

<style scoped></style>
